import axios from "axios";
import React, { ReactNode, useCallback, useMemo, useReducer } from "react";
import { toast } from "react-toastify";
import { matchAPI, resultAPI } from "../../api/api";
import { ResultContext } from "./resultContext";
import {
  getResults,
  getUserVotes as getVotes,
  changeLoading,
  getGlobalPolls as getGlobal,
  ResultInitialState,
  resultReducer,
  getGlobalUserVotes as getGlobalVotes,
} from "./resultReducer";

type ProviderProps = {
  children: ReactNode;
};

const ResultProvider = ({ children }: ProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(resultReducer, ResultInitialState);

  const getGlobalPolls = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await resultAPI.getGlobalPolls();
      dispatch(getGlobal(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getAllSeasonRating = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await resultAPI.getResults();
      dispatch(getResults(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getUserVotes = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await resultAPI.getUserVotes();
      dispatch(getVotes(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getGlobalUserVotes = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await resultAPI.getUserGlobalVotes();
      dispatch(getGlobalVotes(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const Providervalue = useMemo(
    () => ({
      ...state,
      getAllSeasonRating,
      getUserVotes,
      getGlobalPolls,
      getGlobalUserVotes,
    }),
    [state, getAllSeasonRating]
  );

  return <ResultContext.Provider value={Providervalue}>{children}</ResultContext.Provider>;
};

export default ResultProvider;

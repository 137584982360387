export type TypeField = "string" | "select" | "checked";
export type TypePoll = "base" | "lineUp" | "community" | "top1Player";

export type OpponentType = {
  name: string;
  logo: string;
};

export type OptionType = {
  title: string;
  typeField: TypeField;
  description: string;
  selectValues?: string[];
  _id?: string;
  value?: string;
  values?: string;
};

export interface IPoll {
  poll: {
    value: string;
    title: string;
    isEnabled: boolean;
    description: string;
    amountPoints: number;
    options: OptionType[];
    typePoll: TypePoll;
    rightAnswers: string[];
    isRightAnswersAdded: boolean;
    id?: string;
    _id?: string;
    userVote?: any[];
  };
  rightAnswers: string[];
  isRightAnswersAdded: boolean;
}

export interface IMatch {
  isEnabled: boolean;
  eventName: string;
  opponent: OpponentType;
  link: string;
  matchDate: Date;
  isHomeMatch: boolean;
  polls: IPoll[];
  communityPollDeadline: Date;
  lineUpDeadline: Date;
  baseDeadline: Date;
  id?: string;
  isFinished: boolean;
  finishOptions: {
    matchScore: {
      liverpool: number;
      opponent: number;
    };
  };
}

export type MatchStateType = {
  matches: IMatch[];
  match: IMatch | null;
  isLoading: boolean;
  isLoaded: boolean;
  nearestMatchs: IMatch[];
  previousMatchs: IMatch[];
};

export const MatchInitialState: MatchStateType = {
  matches: [],
  match: null,
  isLoading: false,
  isLoaded: false,
  nearestMatchs: [],
  previousMatchs: [],
};

export const matchReducer = (state: MatchStateType, action: ActionsType) => {
  switch (action.type) {
    case "match/reds-club/CHANGE-IS-ISLOADED":
    case "match/reds-club/GET-MATCHES":
    case "match/reds-club/CHANGE-IS-LOADING":
    case "match/reds-club/GET-NEAREST-MATCHES":
    case "match/reds-club/GET-MATCHES-PREVIOUS":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const changeLoaded = (isLoaded: boolean) =>
  ({
    type: "match/reds-club/CHANGE-IS-ISLOADED",
    payload: { isLoaded },
  } as const);

export const getMatchesList = (matches: IMatch[]) =>
  ({
    type: "match/reds-club/GET-MATCHES",
    payload: { matches },
  } as const);

export const getPreviousMatchesList = (previousMatchs: any) =>
  ({
    type: "match/reds-club/GET-MATCHES-PREVIOUS",
    payload: { previousMatchs },
  } as const);

export const getNearesMatchesList = (nearestMatchs: IMatch[]) =>
  ({
    type: "match/reds-club/GET-NEAREST-MATCHES",
    payload: { nearestMatchs },
  } as const);

export const resetMatch = () =>
  ({
    type: "match/reds-club/RESET-MATCH",
    payload: { match: null },
  } as const);

export const changeLoading = (isLoading: boolean) =>
  ({
    type: "match/reds-club/CHANGE-IS-LOADING",
    payload: { isLoading },
  } as const);

type ActionsType =
  | ReturnType<typeof resetMatch>
  | ReturnType<typeof getMatchesList>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof getNearesMatchesList>
  | ReturnType<typeof getPreviousMatchesList>
  | ReturnType<typeof changeLoaded>;

import React, { useMemo, useCallback, useReducer, ReactNode } from "react";
import {
  ProfileContext,
  profileReducer,
  profileInitialState,
  getUserProfile,
  ProfileType,
  changeLoading,
  changeProfileField,
  getUserVotes,
  getUserNFT,
  getExistsNFT,
  getlastSeasonNFT,
} from "./profileReducer";
import { profileAPI } from "../../api/api";
import { toast } from "react-toastify";
import { UseFormSetError } from "react-hook-form";

type PropsType = {
  children: ReactNode;
};

const ProfileProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(profileReducer, profileInitialState);

  const getProfile = useCallback(async () => {
    try {
      // dispatch(changeLoading(true));
      const result = await profileAPI.getProfile();
      dispatch(getUserProfile(result.data.data));
      // dispatch(getExistsNFT(true));
    } catch (e) {
      if (e.response.status === 403) {
        dispatch(getExistsNFT(false));
      } else {
        toast.error("Something goes wrong ! Please try again later.");
        console.log(e);
      }
    } finally {
      // dispatch(changeLoading(false));
    }
  }, []);

  const getLastSeasonNft = useCallback(async () => {
    try {
      const result = await profileAPI.getLastSeasonNft();
      dispatch(getlastSeasonNFT(result.data.data));

      if (result?.data?.data?.tokenId) {
        dispatch(getExistsNFT(true));
      } else {
        dispatch(getExistsNFT(false));
      }
    } catch (e) {
      if (e.response.status === 403) {
        dispatch(
          getlastSeasonNFT({
            name: "",
            image: "",
            description: "",
            tokenId: "",
            token_url: "",
            attributes: [],
          })
        );
        dispatch(getExistsNFT(false));
      } else {
        toast.error("Something goes wrong ! Please try again later.");
        console.log(e);
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const updateProfile = useCallback(
    async (profile: ProfileType, setError: UseFormSetError<ProfileType>) => {
      dispatch(changeLoading(true));
      try {
        const result = await profileAPI.updateProfile(profile);
        dispatch(getUserProfile(result.data.data.user));
        toast.success("Profile updated successfully!");
      } catch (e) {
        // @ts-ignore
        const error = e.response?.data?.errors;
        if (error) {
          const err = Object.entries(error);
          err.forEach((el) => {
            // @ts-ignore
            setError(`${el[0]}`, {
              type: "server",
              message: `${el[1]}`,
            });
          });
        }
      } finally {
        dispatch(changeLoading(false));
      }
    },
    []
  );

  const changeProfile = useCallback(async (name: string, value: string) => {
    dispatch(changeProfileField(name, value));
  }, []);

  const getVotes = useCallback(async () => {
    try {
      const result = await profileAPI.getMyVotes();
      dispatch(getUserVotes(result.data.data));
    } catch (e) {
      if (e.response.status === 403) {
        dispatch(getExistsNFT(false));
      } else {
        toast.error("Something goes wrong ! Please try again later.");
        console.log(e);
      }
    } finally {
    }
  }, []);

  const getNFT = useCallback(async () => {
    try {
      const result = await profileAPI.getMyNFT();
      dispatch(getUserNFT(result.data.data));
      // dispatch(getExistsNFT(true));
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 403) {
        dispatch(getExistsNFT(false));
      }
    } finally {
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      getProfile,
      updateProfile,
      changeProfile,
      getVotes,
      getNFT,
      getLastSeasonNft,
    }),
    [state, getProfile, updateProfile, changeProfile, getVotes, getNFT, getLastSeasonNft]
  );

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;

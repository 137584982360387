exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-nft-tsx": () => import("./../../../src/pages/aboutNFT.tsx" /* webpackChunkName: "component---src-pages-about-nft-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-competitions-tsx": () => import("./../../../src/pages/competitions.tsx" /* webpackChunkName: "component---src-pages-competitions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-nft-tsx": () => import("./../../../src/pages/myNFT.tsx" /* webpackChunkName: "component---src-pages-my-nft-tsx" */),
  "component---src-pages-my-votes-tsx": () => import("./../../../src/pages/myVotes.tsx" /* webpackChunkName: "component---src-pages-my-votes-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-season-polls-tsx": () => import("./../../../src/pages/seasonPolls.tsx" /* webpackChunkName: "component---src-pages-season-polls-tsx" */)
}


import { IMatch, IPoll, OptionType, TypePoll } from "../match/matchReducer";

export interface IRatingResult {
  rating: IRatingResultItem[];
}

export interface IPollUserVote {
  pollInfo: {
    value: string;
    title: string;
    isEnabled: boolean;
    description: string;
    amountPoints: number;
    options: OptionType[];
    typePoll: TypePoll;
    rightAnswers: string[];
    isRightAnswersAdded: boolean;
    id?: string;
    _id?: string;
  };
  answer: string[];
  checked: boolean;
  isRightAnswer: boolean;
  rightAnswers: string[];
  communityAnswer: [];
}

export interface IGlobalPollUserVote {
  poll: {
    _id?: string;
    id?: string;
    title: string;
    description: string;
    winners: string[];
  };
  answer: string;
  user: string;
}

export interface IUserVote {
  matchInfo: IMatch;
  polls: IPollUserVote[];
}

export interface IRatingResultItem {
  user: {
    id: string;
    nickname: string;
    address: string;
  };
  rating: {
    lineUp: {
      amountPoints: number;
      matchCount: number;
    };
    base: {
      amountPoints: number;
      matchCount: number;
    };
    top1Player: {
      amountPoints: number;
      matchCount: number;
    };
  };
}

export type ResultStateType = {
  ratingResults: IRatingResult[];
  isLoading: boolean;
  statRating: [];
  lineUpRating: [];
  top1PlayerRating: [];
  userVotes: IUserVote[];
  globalPolls: IGlobalPoll[];
  globalUserVotes: IGlobalPollUserVote[];
};

export type OptionGlobalPoll = {
  footballer: string;
  typeField: string;
  _id: string;
};

export type IGlobalPoll = {
  value: string;
  title: string;
  winners: string[];
  isEnabled: boolean;
  description: string;
  pollsFinishDate: Date;
  options: OptionGlobalPoll[];
  id?: string;
  _id?: string;
};

export const ResultInitialState: ResultStateType = {
  ratingResults: [],
  statRating: [],
  lineUpRating: [],
  top1PlayerRating: [],
  userVotes: [],
  globalPolls: [],
  globalUserVotes: [],
  isLoading: false,
};

export const resultReducer = (state: ResultStateType, action: ActionsType) => {
  //@ts-ignore
  const results = action.payload?.ratingResults;
  switch (action.type) {
    case "result/reds-club/GET-GLOBAL-USER-VOTES":
    case "result/reds-club/GET-GLOBAL-POLLS":
    case "result/reds-club/GET-USER-VOTES":
    case "result/reds-club/CHANGE-IS-LOADING": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "result/reds-club/GET-RESULTS":
      return {
        ...state,
        ...action.payload,
        statRating: results
          .map((result: IRatingResultItem) => ({
            user: result?.user?.nickname || "Anonym user",
            userId: result.user.id,
            rating: result.rating.base.amountPoints,
            matchCount: result.rating.base.matchCount,
          }))
          .sort(
            (a: any, b: any) => parseFloat(b.rating) - parseFloat(a.rating)
          ),
        lineUpRating: results
          .map((result: IRatingResultItem) => ({
            user: result?.user?.nickname || "Anonym user",
            userId: result.user.id,
            rating: result.rating.lineUp.amountPoints,
            matchCount: result.rating.lineUp.matchCount,
          }))
          .sort(
            (a: any, b: any) => parseFloat(b.rating) - parseFloat(a.rating)
          ),
        top1PlayerRating: results
          .map((result: IRatingResultItem) => ({
            user: result?.user?.nickname || "Anonym user",
            userId: result.user.id,
            rating: result.rating.top1Player.amountPoints,
            matchCount: result.rating.top1Player.matchCount,
          }))
          .sort(
            (a: any, b: any) => parseFloat(b.rating) - parseFloat(a.rating)
          ),
      };
    default:
      return state;
  }
};

export const getGlobalUserVotes = (globalUserVotes: IGlobalPollUserVote[]) =>
  ({
    type: "result/reds-club/GET-GLOBAL-USER-VOTES",
    payload: { globalUserVotes },
  } as const);

export const getGlobalPolls = (globalPolls: IGlobalPoll[]) =>
  ({
    type: "result/reds-club/GET-GLOBAL-POLLS",
    payload: { globalPolls },
  } as const);

export const getResults = (ratingResults: IRatingResult[]) =>
  ({
    type: "result/reds-club/GET-RESULTS",
    payload: { ratingResults },
  } as const);

export const getUserVotes = (userVotes: IUserVote[]) =>
  ({
    type: "result/reds-club/GET-USER-VOTES",
    payload: { userVotes },
  } as const);

export const changeLoading = (isLoading: boolean) =>
  ({
    type: "result/reds-club/CHANGE-IS-LOADING",
    payload: { isLoading },
  } as const);

type ActionsType =
  | ReturnType<typeof getResults>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof getUserVotes>
  | ReturnType<typeof getGlobalPolls>
  | ReturnType<typeof getGlobalUserVotes>;

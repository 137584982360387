import React, { FC, ReactNode, SetStateAction, useCallback, useEffect, useState } from "react";
import { configAPI } from "../../api/api";
import { AuthContext } from "./authContext";
const storageName: string | null = "userData";
interface AuthProviderProps {
  children: ReactNode;
}
const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  //   const { login, logout, token, userId } = useAuth();
  //   const isAuthentificated: boolean = !!token;
  const [networkId, setNetmorkId] = useState(null);
  const [token, setToken] = useState<string | null>(null);
  const [ready, setReady] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);

  const login = useCallback(
    (jwtToken: SetStateAction<null | string>, id: SetStateAction<null | string>) => {
      setToken(jwtToken);
      setUserId(id);

      localStorage.setItem(
        storageName,
        JSON.stringify({
          userId: id,
          token: jwtToken,
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    localStorage.removeItem(storageName);
  }, []);

  const getConfigNetwork = useCallback(async () => {
    const data = await configAPI.getBlockChainInfo();

    setNetmorkId(data.data.chainId);
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));

    if (data && data.token) {
      login(data.token, data.userId);
    }
    setReady(true);
  }, [login]);
  const isAuthentificated = !!token;

  return (
    <AuthContext.Provider
      value={{ logout, login, token, userId, isAuthentificated, getConfigNetwork, networkId }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import { createContext } from 'react';
import { MatchInitialState, MatchStateType } from './matchReducer';

interface MatchContextValue extends MatchStateType {
  getMatches: () => Promise<void>;
  clearMatch: () => void;
  getNearestMatches: () => Promise<void>;
  getPreviousMatches: () => Promise<void>;
}

export const MatchContext = createContext<MatchContextValue>({
  ...MatchInitialState,
  getMatches: () => Promise.resolve(),
  getNearestMatches: () => Promise.resolve(),
  clearMatch: () => {},
  getPreviousMatches: () => Promise.resolve(),
});

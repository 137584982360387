import axios from 'axios';
import React, { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-toastify';
import { matchAPI } from '../../api/api';
import { MatchContext } from './matchContext';
import {
  changeLoaded,
  changeLoading,
  getMatchesList,
  getNearesMatchesList,
  getPreviousMatchesList,
  MatchInitialState,
  matchReducer,
  resetMatch,
} from './matchReducer';

type ProviderProps = {
  children: ReactNode;
};

const MatchProvider = ({ children }: ProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(matchReducer, MatchInitialState);

  const getMatches = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.getMatches();
      dispatch(getMatchesList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);
  const getPreviousMatches = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.getPreviousMatchs();
      dispatch(getPreviousMatchesList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
      dispatch(changeLoaded(true));
    }
  }, []);

  const getNearestMatches = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.getNearestMatchs();
      dispatch(getNearesMatchesList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
      dispatch(changeLoaded(true));
    }
  }, []);

  const clearMatch = useCallback(async () => {
    dispatch(resetMatch());
  }, []);

  const Providervalue = useMemo(
    () => ({
      ...state,
      getMatches,
      getNearestMatches,
      clearMatch,
      getPreviousMatches,
    }),
    [state, getMatches, clearMatch, getNearestMatches, getPreviousMatches]
  );

  return <MatchContext.Provider value={Providervalue}>{children}</MatchContext.Provider>;
};

export default MatchProvider;

import { createContext } from "react";
import { UseFormSetError } from "react-hook-form";

export type GenderType = "Male" | "Female";

export type ProfileType = {
  email: string;
  name: {
    first: string;
    last: string;
    nickname: string;
  };
  address: string;
  fanSince: string;
  location: {
    country: string;
    city: string;
  };
  gender: GenderType;
  description: string;
  gameVisits: string;
  topPlayers: string;
  birthDay: Date;
  lastMemberNft: INft;
  rating: {
    amountPoints: number;
    amountMatches: number;
  };
};

export interface IProfileState {
  profile: ProfileType | null;
  isLoading: boolean;
  votes: any[];
  collectableNFT: [];
  lastMemberNft: INft;
  seasonNFT: [];
  isSeasonTokenExists: boolean;
}

export interface INft {
  name: string;
  image: string;
  description: string;
  tokenId: string;
  token_url: string;
  attributes: AttributeNFT[];
}

export type AttributeNFT = {
  trait_type: string;
  value: string;
};

export const profileInitialState: IProfileState = {
  profile: null,
  isLoading: true,
  isSeasonTokenExists: false,
  lastMemberNft: {
    name: "",
    image: "",
    description: "",
    tokenId: "",
    token_url: "",
    attributes: [],
  },
  votes: [],
  collectableNFT: [],
  seasonNFT: [],
};

export const profileReducer = (state: IProfileState, action: ActionsType): IProfileState => {
  switch (action.type) {
    case "profile/redsClub/GET-PROFILE":
    case "profile/redsClub/GET-EXISTS-NFT":
    case "profile/redsClub/CHANGE-IS-LOADING":
    case "profile/redsClub/GET-VOTES":
    case "profile/redsClub/GET-LAST-SEASON-NFT":
      return {
        ...state,
        ...action.payload,
      };
    case "profile/redsClub/CHANGE-PROFILE":
      const { value, name } = action.payload;
      const names = name.split(".");
      if (names.length > 1) {
        return {
          ...state,
          //@ts-ignore
          profile: {
            ...state.profile,
            [names[0]]: {
              //@ts-ignore
              ...state.profile?.[names[0]],
              [names[1]]: value,
            },
          },
        };
      }
      return {
        ...state,
        //@ts-ignore
        profile: {
          ...state.profile,
          [name]: value,
        },
      };
    case "profile/redsClub/GET-NFT":
      const { collectable, membership } = action.payload.nft;

      return {
        ...state,
        seasonNFT: membership,
        collectableNFT: collectable,
      };
    default:
      return state;
  }
};

export const getlastSeasonNFT = (lastMemberNft: INft) =>
  ({
    type: "profile/redsClub/GET-LAST-SEASON-NFT",
    payload: { lastMemberNft },
  } as const);

export const getExistsNFT = (isSeasonTokenExists: boolean) =>
  ({
    type: "profile/redsClub/GET-EXISTS-NFT",
    payload: { isSeasonTokenExists },
  } as const);

export const getUserProfile = (profile: ProfileType) =>
  ({
    type: "profile/redsClub/GET-PROFILE",
    payload: { profile },
  } as const);

export const changeLoading = (isLoading: boolean) => {
  return {
    type: "profile/redsClub/CHANGE-IS-LOADING",
    payload: { isLoading },
  } as const;
};

export const changeProfileField = (name: string, value: string) =>
  ({
    type: "profile/redsClub/CHANGE-PROFILE",
    payload: { name, value },
  } as const);

export const getUserVotes = (votes: any) =>
  ({
    type: "profile/redsClub/GET-VOTES",
    payload: { votes },
  } as const);

export const getUserNFT = (nft: any) =>
  ({
    type: "profile/redsClub/GET-NFT",
    payload: { nft },
  } as const);

type ActionsType =
  | ReturnType<typeof getUserProfile>
  | ReturnType<typeof changeProfileField>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof getUserVotes>
  | ReturnType<typeof getUserNFT>
  | ReturnType<typeof getExistsNFT>
  | ReturnType<typeof getlastSeasonNFT>;

interface ProfileContextValue extends IProfileState {
  getProfile: () => Promise<void>;
  updateProfile: (profile: ProfileType, setError: UseFormSetError<ProfileType>) => Promise<void>;
  changeProfile: (name: string, value: string) => void;
  getVotes: () => Promise<void>;
  getNFT: () => Promise<void>;
  getLastSeasonNft: () => Promise<void>;
}

export const ProfileContext = createContext<ProfileContextValue>({
  ...profileInitialState,
  getProfile: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  getVotes: () => Promise.resolve(),
  getNFT: () => Promise.resolve(),
  getLastSeasonNft: () => Promise.resolve(),
  changeProfile: () => {},
});

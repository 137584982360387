import { createContext } from "react";

function noop() {}
export type IAuthContent = {
  token: null | string;
  userId: null | string;
  login: (jwt: string, id: string) => void;
  logout: () => void;
  isAuthentificated: boolean;
  getConfigNetwork: () => void;
  networkId: number | null;
};

export const AuthContext = createContext<IAuthContent>({
  token: null,
  userId: null,
  logout: noop,
  login: noop,
  isAuthentificated: false,
  getConfigNetwork: noop,
  networkId: null,
});

import { createContext } from "react";
import { ResultInitialState, ResultStateType } from "./resultReducer";

interface ResultContext extends ResultStateType {
  getAllSeasonRating: () => Promise<void>;
  getUserVotes: () => Promise<void>;
  getGlobalPolls: () => Promise<void>;
  getGlobalUserVotes: () => Promise<void>
}

export const ResultContext = createContext<ResultContext>({
  ...ResultInitialState,
  getAllSeasonRating: () => Promise.resolve(),
  getUserVotes: () => Promise.resolve(),
  getGlobalPolls: () => Promise.resolve(),
  getGlobalUserVotes: () => Promise.resolve(),
});

import React, { FC, ReactNode } from "react";
import { Web3ReactHooks, Web3ReactProvider } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { HelmetProvider } from "react-helmet-async";
import type { Connector } from "@web3-react/types";
import { WalletConnect } from "@web3-react/walletconnect";

import { hooks as metaMaskHooks, metaMask } from "../connectors/metaMask";

import { hooks as walletConnectHooks, walletConnect } from "../connectors/walletConnect";
import AuthProvider from "../context/auth/AuthProvider";
import MatchProvider from "../context/match/MatchProvider";
import ProfileProvider from "../context/profile/profileContext";
import ResultProvider from "../context/result/ResultProvider";

function getName(connector: Connector) {
  if (connector instanceof MetaMask) return "MetaMask";
  if (connector instanceof WalletConnect) return "WalletConnect";

  return "Unknown";
}

const connectors: [MetaMask | WalletConnect, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
];

const WrappDApp = ({ element }: { element: ReactNode }) => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <ProfileProvider>
          <MatchProvider>
            <ResultProvider>
              <Web3ReactProvider connectors={connectors}>{element}</Web3ReactProvider>
            </ResultProvider>
          </MatchProvider>
        </ProfileProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default WrappDApp;

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import { LoginType, User } from '../reducers/authReducer';
// import { IMatch } from '../reducers/matchReducer';
// import { IPoll } from '../reducers/pollReducer';

export type User =
  | {
      _id: string;
    }
  | null
  | undefined;

export type LoginType = {
  "x-sign": string;
  "x-message": string;
};

type LoginResponseType = {
  token: { token: string };
  user: { _id: string };
};

export type FootballerType = {
  name: string;
  isActive: boolean;
};

export type DataAnswerType = {
  pollId: string;
  answer: string[];
};

export type DataAnuserCommunitisType = {
  commynityAnswers: {
    pollId: string | undefined;
    answer: {
      footballer: string;
      stars: number[];
    }[];
  }[];
};

export type DataAanswersType = {
  answers: DataAnswerType[];
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "https://api.redsclub.io/",
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const { headers } = config;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = localStorage.getItem("userData");
  if (data) {
    const token = JSON.parse(data).token;
    if (token && headers) {
      headers.Authorization = token;
    }
  }

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // localStorage.removeItem('redsclubToken');
      // window.location.replace('/authentication');
    }
    return Promise.reject(error);
  }
);

export const authAPI = {
  async login(data: LoginType): Promise<LoginResponseType> {
    return instance
      .post("/WalletAuth", "", { headers: data })
      .then((res: { data: LoginResponseType }): LoginResponseType => res.data);
  },
};

export const footballerAPI = {
  async getFootballers(): Promise<AxiosResponse> {
    return instance.get("/footballer");
  },
  async getFootballer(id: string): Promise<AxiosResponse> {
    return instance.get(`/footballer/${id}`);
  },
};

export const pollAPI = {
  async getPolls(): Promise<AxiosResponse> {
    return instance.get("/poll");
  },
  async getPoll(id: string): Promise<AxiosResponse> {
    return instance.get(`/poll/${id}`);
  },
  async setPoll(id: string, data: DataAanswersType): Promise<AxiosResponse> {
    return instance.post(`/polling/match/${id}`, data);
  },
  async setPollCommunity(id: string, data: DataAnuserCommunitisType): Promise<AxiosResponse> {
    return instance.post(`/polling/match/${id}?isCommunityAnswers=true`, data);
  },
  async setPollBase(id: string, data: DataAanswersType): Promise<AxiosResponse> {
    return instance.post(`/polling/match/basePoll/${id}`, data);
  },
};

export const matchAPI = {
  async getMatches(): Promise<AxiosResponse> {
    return instance.get("/match");
  },
  async getMatch(id: string): Promise<AxiosResponse> {
    return instance.get(`/match/${id}`);
  },
  async getNearestMatchs(): Promise<AxiosResponse> {
    return instance.get(`/match/nearest`);
  },
  async getPreviousMatchs(): Promise<AxiosResponse> {
    return instance.get(`/match/previous`);
  },
};

export const profileAPI = {
  async getProfile(): Promise<AxiosResponse> {
    return instance.get("user/profile");
  },
  async updateProfile(profile: any): Promise<AxiosResponse> {
    return instance.patch("user/profile", profile);
  },
  async getMyVotes(): Promise<AxiosResponse> {
    return instance.get("user/myVotes");
  },
  async getMyNFT(): Promise<AxiosResponse> {
    return instance.get("user/nft");
  },
  async getLastSeasonNft(): Promise<AxiosResponse> {
    return instance.get("user/lastMemberNft");
  },
};

export const resultAPI = {
  async getResults(): Promise<AxiosResponse> {
    return instance.get("/user/rating");
  },

  async getUserVotes(): Promise<AxiosResponse> {
    return instance.get("/user/myVotes");
  },
  async getGlobalPolls(): Promise<AxiosResponse> {
    return instance.get("/poll/globalPolls");
  },
  async setGlobalPoll(idPoll: string, data: string): Promise<AxiosResponse> {
    return instance.post(`/polling/match//global/${idPoll}`, data);
  },
  async getUserGlobalVotes(): Promise<AxiosResponse> {
    return instance.get("/user/myGlobalVotes");
  },
};

export const configAPI = {
  async getBlockChainInfo(): Promise<AxiosResponse> {
    return instance.get("/settings/blockchainInfo");
  },
};
